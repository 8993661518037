<template>
  <div class="map_wrap">
    <iframe
      id="gmap_canvas"
      src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d56708.36461027776!2d-82.57294971661771!3d27.335938981177353!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x88c337e69db2c22b%3A0xe335341d1d5715d9!2sSarasota%2C%20FL!5e0!3m2!1sen!2sus!4v1642110453071!5m2!1sen!2sus"
      frameborder="0"
      scrolling="no"
      marginheight="0"
      marginwidth="0"
    ></iframe>
  </div>
  <!-- End Google Map -->
</template>

<script>
export default {};
</script>

<style lang="scss" scoped></style>
