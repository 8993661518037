<template>
  <div class="container">
    <div v-if="activetab === 1" >
      <div class="portfolio_inner pt-0 pb-30">
        <ul>
          <CoolLightBox :items="allItems" :index="index" @close="index = null">
          </CoolLightBox>
          <li v-for="(image, imageIndex) in allItems" :key="imageIndex">
            <div class="list_inner">
              <div class="image" @click="index = imageIndex">
                <img src="../../assets/img/thumbs/1-1.jpg" alt="Portfolio" />
                <div
                  class="main"
                  :style="{ backgroundImage: 'url(' + image.thumb + ')' }"
                ></div>
              </div>
              <!-- End .image -->
              <div class="title">
                <h3 @click="index = imageIndex">{{ image.portfolioName }}</h3>
                <span>{{ image.portfolioType }}</span>
              </div>
            </div>
          </li>
          <!-- End li -->
        </ul>
      </div>

      <!-- End .portfolio_list -->
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      active: false,
      activetab: 1,
      allItems: [
        {
          src: require("../..//assets/img/about/4.jpg"),
          thumb: require("../../assets/img/about/4.jpg"),
        },
        {
          src: require("../../assets/img/about/5.jpg"),
          thumb: require("../../assets/img/about/5.jpg"),
        },
        {
          src: require("../../assets/img/about/6.jpg"),
          thumb: require("../../assets/img/about/6.jpg"),
        },
      ],
      index: null,
    };
  },
};
</script>

<style lang="scss" scoped></style>
