<template>
  <div class="container">
    <div class="positon-relative waxon_tm_main_title">
      <div class="title">
        <h3>Videos<span class="bg">Videos</span></h3>
      </div>
    </div>

    <div v-if="activetab === 1" class="tabcontent">
      <div class="portfolio_inner">
        <ul>
          <CoolLightBox :items="allItems" :index="index" @close="index = null">
          </CoolLightBox>
          <li v-for="(image, imageIndex) in allItems" :key="imageIndex">
            <div class="list_inner">
              <div class="image" @click="index = imageIndex">
                <img src="../../assets/img/thumbs/1-1.jpg" alt="Portfolio" />
                <div
                  class="main"
                  :style="{ backgroundImage: 'url(' + image.thumb + ')' }"
                ></div>
              </div>
              <!-- End .image -->
              <div class="title">
                <h3 @click="index = imageIndex">{{ image.portfolioName }}</h3>
                <span>{{ image.portfolioType }}</span>
              </div>
            </div>
          </li>
          <!-- End li -->
        </ul>
        <div class="waxon_tm_button" v-scroll-reveal="{ delay: 100 }" style="text-align:center; margin-top:20px;">
          <a href="https://www.youtube.com/c/Pashaviolin/videos" target="_blank">More on my YouTube Channel</a>
        </div>
      </div>

      <!-- End .portfolio_list -->
    </div>
    <!-- End .tabcontent 1 -->

  </div>
</template>

<script>
export default {
 data() {
    return {
      active: false,
      activetab: 1,
      allItems: [
        {
          src: "https://youtu.be/yJk_tA1_3Eo",
          thumb: "https://img.youtube.com/vi/yJk_tA1_3Eo/hqdefault.jpg",
          portfolioName: "Yiruma - Indigo",
          portfolioType: "Youtube",
        },
        {
          src: "https://youtu.be/eJ4h7j5BnZU",
          thumb: "https://img.youtube.com/vi/eJ4h7j5BnZU/hqdefault.jpg",
          portfolioName: "John Mayer - New Light",
          portfolioType: "Youtube",
        },
        {
          src: "https://youtu.be/rG-h0RNlmEs",
          thumb: "https://img.youtube.com/vi/rG-h0RNlmEs/hqdefault.jpg",
          portfolioName: "Billie Eilish & Khalid - Lovely",
          portfolioType: "Youtube",
        },
      ],
      index: null,
    };
  },
};
</script>

<style lang="scss" scoped></style>
