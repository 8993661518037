<template>
  <div>
    <Header />
    <!-- End Header -->

    <Slider />
    <!-- End Slider -->

    <About />
    <About2 />
    <!-- End About -->

    <div class="cta-section">
      <div class="bg_image"></div>
      <div class="container">
        <div class="contact_inner">
          <div class="waxon_tm_main_title">
            <div
              class="title"
              v-scroll-reveal="{ delay: 20, distance: '20px' }"
            >
              <h3>Book For Your Next Event Today<span class="bg">Book</span></h3>
            </div>
          </div>
          <div class="cta-button">
            <a type="button" class="ib-button" href="tel:9412765854">
              <span>Call Now</span>
            </a>
          </div>
        </div>
      </div>
    </div>

    <div class="waxon_tm_portoflio" id="gallery">
      <Portfolio />
    </div>
    <!-- End Portfolio -->

    <div class="waxon_tm_portoflio" id="videos">
      <Videos />
    </div>
    <!-- End Videos -->

    <Testimonial />
    <!-- End Testimonail -->

    <div class="waxon_tm_contact" id="contact">
      <div class="bg_image"></div>
      <div class="container">
        <div class="contact_inner">
          <div class="waxon_tm_main_title">
            <div
              class="title"
              v-scroll-reveal="{ delay: 20, distance: '20px' }"
            >
              <h3>Get in Touch<span class="bg">Contact</span></h3>
            </div>
          </div>
          <div class="desc" v-scroll-reveal="{ delay: 30, distance: '20px' }">
            <p>
              Tell me what you are looking for...
            </p>
            <p>
              ...or call me at <span class="contact-phone"><a href="tel:9412765854">(941) 276-5854</a></span>
            </p>
          </div>
          <div class="wrapper">
            <div class="left" v-scroll-reveal="{ delay: 40 }">
              <Contact />
            </div>
            <div class="right" v-scroll-reveal="{ delay: 60 }">
              <Map />
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- End contact -->

    <Footer />

    <!-- End End Footer -->
  </div>
</template>

<script>
import Header from "../components/Header.vue";
import Slider from "../components/slider/Slider.vue";
import About from "../components/about/About.vue";
import About2 from "../components/about/AboutTwo.vue";
// import Service from "../components/services/Service.vue";
import Portfolio from "../components/portfolio/Portfolio.vue";
import Testimonial from "../components/testimonial/Testimonial.vue";
import Videos from "../components/portfolio/PortfolioTwo.vue";
import Contact from "../components/Contact.vue";
import Map from "../components/Map.vue";
import Footer from "../components/Footer.vue";
export default {
  components: {
    Header,
    Slider,
    About,
    About2,
    Portfolio,
    Testimonial,
    Videos,
    Contact,
    Map,
    Footer,
  },
};
</script>

<style lang="scss" scoped>
.cta-section {
  width: 100%;
  margin-top: 100px;
  padding: 100px 0;
  display: inline-block;
  background: #222;

  .contact_inner {
    display: flex;
  }

  .cta-button {
    flex: none;
  }

  .ib-button:hover {
    color: #fafafa;
    background: #444;
  }

  h3 {
    color: #fafafa;
  }
}

@media (max-width: 767px) {
  .contact_inner {
    flex-direction: column;
  }

  .cta-button {
    margin-top: 20px;

    a {
      width: 100%;
      text-align: center;
    }
  }
}

.contact-phone {
  font-family: 'Montserrat';
  font-style: initial;
  font-weight: bold;
  font-size: 1rem;

  a {
    color: inherit;
    text-decoration: none;
  }
}
</style>
