<template>
  <div class="waxon_tm_all_wrap preview-page" data-color="crimson">
    <Header />
    <!-- End Header -->

    <About />

    <div class="waxon_tm_contact pt-0">
      <div class="bg_image"></div>
      <div class="container">
        <div class="contact_inner">
          <div class="waxon_tm_main_title">
            <div
              class="title"
              v-scroll-reveal="{ delay: 20, distance: '20px' }"
            >
              <h3>Get in Touch<span class="bg">Contact</span></h3>
            </div>
          </div>
          <div class="desc" v-scroll-reveal="{ delay: 30, distance: '20px' }">
            <p>
              Tell me what you are looking for.
            </p>
          </div>
          <div class="wrapper">
            <div class="left" v-scroll-reveal="{ delay: 40 }">
              <Contact />
            </div>
            <div class="right" v-scroll-reveal="{ delay: 60 }">
              <Map />
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
  <!-- End wrapper all -->
</template>

<script>
import Header from "../components/HeaderAbout.vue";
import About from "../components/about/AboutThree.vue";
import Contact from "../components/Contact.vue";
import Map from "../components/Map.vue";

export default {
  components: {
    Header,
    About,
    Contact,
    Map
  },
  data() {
    return {
      prevImg: require("../assets/img/intro/preview.jpg"),
    };
  },
  mounted() {
    document.body.classList.add("preview-page");
  },
  destroyed() {
    document.body.classList.remove("preview-page");
  },
};
</script>

<style scoped>
.preview-page .back-top {
  display: none;
}
</style>
