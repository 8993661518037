<template>
  <div class="waxon_tm_about" id="about">
    <div class="container">
      <div class="about_inner">
        <div class="left" v-scroll-reveal="{ delay: 40 }">
          <img
            class="thumbnail"
            src="../../assets/img/about/3.jpg"
          />
        </div>
        <!-- End left thumbnail -->
        <div class="right">
          <div class="name" v-scroll-reveal="{ delay: 40 }">
            <h3>About Me<span class="bg">About</span></h3>
            <span class="job"></span>
          </div>
          <div class="text">
            <p v-scroll-reveal="{ delay: 60 }">
              Re-discover the classic violin sound with a modern twist & artsy ‘never done before’ approach. My name is Paul Leonov, it’s a pleasure to meet & have you on my site! 
              Born and raised in Moscow, currently living in Sarasota, FL.
            </p>
            <p v-scroll-reveal="{ delay: 100 }">
              My mission and goal is to spread the sound of music through the strings in unique (sometimes abstract) ways and to motivate the younger crowd to pursue the magic of music in any shape or form. 
              I believe that music is a gift from God and it’s our duty to explore & create it in our own ways - which is what makes it so great in my opinion.
            </p>
            <p v-scroll-reveal="{ delay: 140 }">
              I’ve played well over 400+ private events in the last 4 years of catering music, which means the crowd reading, song selection & overall vibe always fits the setting & guests are beyond satisfied.
            </p>
          </div>
        </div>
        <!-- End right content -->
      </div>

      <div class="waxon_tm_portoflio pb-30">
        <Gallery />
      </div>
            
      <div class="about_inner">
        <div class="left">
          <div class="text">
            <p v-scroll-reveal="{ delay: 60 }">
              Right, since I got you on this page, let’s use this time & get to know each other a little bit better! 
            </p>
            <p v-scroll-reveal="{ delay: 100 }">
              I’m just a simple, easy-going dude who enjoys the little things in life. 
              I love various types of music, all forms of art, and most of all – nature. 
              Being born & raised in Moscow while traveling the world (thanks to my family) has truly given me a unique perspective on many things that continue to make me thankful every single day. I’ve always loved sports, have done olympic swimming for 4 years, gymnastics & diving, played football in High School & went to States for weightlifting (where I placed 4th in my weight class).
            </p>
            <p v-scroll-reveal="{ delay: 140 }">
              Every day is a blessing to me & I try to take it by storm while putting smiles on other people’s faces, whether it’s through conversation or music. I appreciate you taking a moment to get to know me - it’s your turn to tell me about You now! ⬇️
            </p>
          </div>
        </div>
        <div class="right">
          <img
            class="thumbnail"
            src="../../assets/img/about/7.jpg"
          />
        </div>
      </div>
    </div>
  </div>
  <!-- End about -->
</template>

<script>
import Gallery from "../portfolio/PortfolioThree.vue";

export default {
  components: {
    Gallery
  },
  data() {
    return {
      isActive: false,
      isVisible: false,
    };
  },
  methods: {
    showModal: function() {
      this.isActive = true;
      this.isVisible = true;
    },
    closeModal: function() {
      this.isActive = false;
      this.isVisible = false;
    },
  },
};
</script>

<style scoped>
.about_inner {
  margin-bottom: 100px;
  padding-bottom: 50px;
}

.about_inner:last-child {
  margin-bottom: 0;
  margin-bottom: 0;
}

.waxon_tm_portoflio {
  padding: 0 !important;
  background-color: #fff !important;
}
</style>