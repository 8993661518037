<template>
  <div class="waxon_tm_testimonials" id="reviews" style="background-color: #f8f9fa;">
    <div class="container">
      <div class="waxon_tm_main_title">
        <div class="title" v-scroll-reveal="{ delay: 20, distance: '20px' }">
          <h3>Bringing You the Sound You Desire<span class="bg">Reviews</span></h3>
          <p class="mt-30">I treat every note as a piece of art and as a musician, it is my mission to make the audience feel the music. 
            <br> In my opinion, that is what separates mainstream musicians and true artists of music.</p>
        </div>
      </div>
      <!-- waxon_tm-main_title -->

      <div class="testi_inner">
        <img src="../../assets/img/home/reviews.png" alt="">
      </div>
      <!-- End testi_inner -->
    </div>
  </div>
  <!-- End Testimonials -->
</template>

<script>
export default {
  data() {
    return {
      activetab: 1,
      imgOne: require("../../assets/img/about/1.jpg"),
      imgTwo: require("../../assets/img/about/dj.jpg"),
      imgThree: require("../../assets/img/about/roof.jpg"),
    };
  },
};
</script>

<style lang="scss" scoped></style>
