<template>
  <div class="fields">
    <ValidationObserver v-slot="{ handleSubmit }">
      <form class="contact_form" @submit.prevent="handleSubmit(onSubmit)" v-if="!submitted && !submitError">
        <div class="first">
          <ul>
            <ValidationProvider
              name="name"
              rules="required"
              v-slot="{ errors }"
            >
              <li>
                <input
                  v-model="formData.name"
                  type="text"
                  placeholder="Name"
                  autocomplete="off"
                />
                <span class="inpur-error">{{ errors[0] }}</span>
              </li>
            </ValidationProvider>

            <ValidationProvider
              name="email"
              rules="required|email"
              v-slot="{ errors }"
            >
              <li>
                <input
                  type="text"
                  rules="required|email"
                  v-model="formData.email"
                  placeholder="Email"
                />
                <span class="inpur-error">{{ errors[0] }}</span>
              </li>
            </ValidationProvider>

            <ValidationProvider
              name="phone"
              rules="required|numeric|max:12"
              v-slot="{ errors }"
            >
              <li>
                <input
                  type="text"
                  rules="required|numeric"
                  v-model="formData.phone"
                  placeholder="Phone"
                />
                <span class="inpur-error">{{ errors[0] }}</span>
              </li>
            </ValidationProvider>

            <ValidationProvider
              name="message"
              rules="required"
              v-slot="{ errors }"
            >
              <li>
                <textarea
                  v-model="formData.message"
                  placeholder="Briefly describe what you are looking for"
                ></textarea>
                <span class="inpur-error">{{ errors[0] }}</span>
              </li>
            </ValidationProvider>
          </ul>
        </div>
        <div class="tokyo_tm_button">
          <button type="submit" class="ib-button" :disabled="submitDisabled">
            Send Message
          </button>
        </div>
      </form>
      <div v-else-if="submitted && !submitError">
        Your message has been sent successfully! I'll be in touch with you shortly!
      </div>
      <div v-else>
        Error sending message. Please try again later or email me at <a href="mailto:leon.pavel98@gmail.com">leon.pavel98@gmail.com</a>.
      </div>
    </ValidationObserver>
  </div>
</template>

<script>
import axios from 'axios';
import { ValidationObserver } from "vee-validate";
import { ValidationProvider } from "vee-validate/dist/vee-validate.full.esm";
export default {
  components: {
    ValidationObserver,
    ValidationProvider,
  },
  data() {
    return {
      submitDisabled: false, 
      submitted: false,
      submitError: false,
      formData: {
        name: "",
        email: "",
        phone: "",
        message: "",
      },
    };
  },
  methods: {
    onSubmit() {
      this.submitDisabled = true;
      axios.post('https://us-central1-paul-leonov.cloudfunctions.net/sendEmail', { name: this.formData.name, email: this.formData.email, phone: this.formData.phone, message: this.formData.message })
      .then(() => {
        this.submitted = true;
      })
      .catch((error) => {
        this.submitError = true;
        console.log(error);
      });
    },
  },
};
</script>

<style lang="scss">
.inpur-error {
  color: red;
  font-size: 14px;
  padding-top: 5px;
}
</style>
