<template>
  <div class="waxon_tm_about" id="about">
    <div class="container">
      <div class="about_inner">
        <div class="left" v-scroll-reveal="{ delay: 40 }">
          <img
            class="thumbnail"
            src="../../assets/img/about/2.jpg"
            alt="man with sunglass"
          />
        </div>
        <!-- End left thumbnail -->
        <div class="right">
          <div class="name" v-scroll-reveal="{ delay: 40 }">
            <h3>Live Violin &amp; Events<span class="bg">About</span></h3>
            <span class="job"></span>
          </div>
          <div class="text">
            <p v-scroll-reveal="{ delay: 60 }">
              Re-discover the classic violin sound with a modern twist & artsy ‘never done before’ approach. My name is Paul Leonov, it’s a pleasure to meet & have you on my site! 
              Born and raised in Moscow, currently living in Sarasota, FL.
            </p>
            <p v-scroll-reveal="{ delay: 100 }">
              My mission and goal is to spread the sound of music through the strings in unique (sometimes abstract) ways and to motivate the younger crowd to pursue the magic of music in any shape or form. 
              I believe that music is a gift from God and it’s our duty to explore & create it in our own ways - which is what makes it so great in my opinion.
            </p>
            <p v-scroll-reveal="{ delay: 140 }">
              I’ve played well over 400+ private events in the last 4 years of catering music, which means the crowd reading, song selection & overall vibe always fits the setting & guests are beyond satisfied.
            </p>
          </div>
          <div class="waxon_tm_button" v-scroll-reveal="{ delay: 100 }">
            <router-link to="/about">Take a Closer Look at my Story</router-link>
          </div>
        </div>
        <!-- End right content -->
      </div>
    </div>
  </div>
  <!-- End about -->
</template>

<script>
export default {
  data() {
    return {
      isActive: false,
      isVisible: false,
    };
  },
  methods: {
    showModal: function() {
      this.isActive = true;
      this.isVisible = true;
    },
    closeModal: function() {
      this.isActive = false;
      this.isVisible = false;
    },
  },
};
</script>
